<template>
  <main-layout pageTitle="Leagues">
    <h3 class="ion-padding-horizontal">Page not Found</h3>
    <div class="px-3">
      <p>404 Page was not found.</p>
      <a href="/">Return to the app.</a>
    </div>
  </main-layout>
</template>

<script>
export default {};
</script>
